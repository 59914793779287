//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { S25DatePatternComponent } from "./s25.datepattern.component";
import { S25DatepickerModule } from "../s25-datepicker/s25.datepicker.module";
import { S25DatePatternPickerComponent } from "./s25.datepattern.picker.component";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25PipesModule } from "../../pipes/s25.pipes.module";
import { S25RadioModule } from "../s25-radio/s25.radio.module";

@NgModule({
    declarations: [S25DatePatternComponent, S25DatePatternPickerComponent],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NgbDatepickerModule,
        S25DatepickerModule,
        S25CheckboxModule,
        S25DropdownPaginatedModule,
        S25EditableModule,
        S25PipesModule,
        S25RadioModule,
    ],
    providers: [S25DatePatternComponent, S25DatePatternPickerComponent],
    exports: [S25DatePatternComponent, S25DatePatternPickerComponent],
})
export class S25DatePatternModule {
    constructor() {}
}
