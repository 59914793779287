/**
 * Separate module to work-around circular dependency:
 * s25-datefilter needs this parser, and s25-util needs dateFilter
 *
 * @author mikhail, devin
 */
import { S25Util } from "../../util/s25-util";

export class s25Dateparser {
    public static parse(dateStr: string | Date) {
        // Invalid Date - return undefined for undefined
        if (!dateStr) {
            return undefined;
        }

        // Date object - clone and return
        if (Object.prototype.toString.call(dateStr) === "[object Date]") {
            return S25Util.date.clone(dateStr) as any;
        }

        return window.dayjs(dateStr).toDate();
    }
}
