//@author: devin

export class S25DateformatConst {
    public static S25_DATE_FORMAT_PREFIX: string = "S25|";
    public static S25_DATE_FORMAT_CONST: any = {
        dateprefs: {
            prefsDefault: {
                month: "I",
                day: "I",
                weekstart: 7,
                dateorder: "MDY",
                datesep: " ",
                // hardcoded consts
                numday: "numday",
                year: "year",
            },

            // s25 to Angular dateformat mapping
            prefsMapping: {
                month: {
                    I: "MMM", // Sep
                    U: "NNN", // SEP
                    L: "nnn", // sep
                    N: "M", // 09
                },
                day: {
                    I: "EEE", // Sun
                    U: "FFF", // SUN
                    L: "fff", // sun
                },
                // only two special values ('S', 'N') - others are treated as physical separator value
                datesep: {
                    S: " ", // space
                    N: "", // none
                },
                // hardcoded consts
                numday: { numday: "dd" },
                year: { year: "yyyy" },
            },

            // s25 to Angular dateformat mapping
            prefsDateOrderMapping: {
                M: "month",
                D: "numday",
                Y: "year",
            },
        },
        timeprefs: {
            prefsDefault: { timedisplay: "12ampm" },
            prefsMapping: {
                timedisplay: { "12ampm": "h:mm a", 12: "h:mm", 24: "H:mm", ampm: "a" },
            },
        },
        // hard-coded S25 POST / PUT format, which results in a string like '20140106T130000'
        // 20150728: validation (log_id=2385): Conversion failed when converting date and/or time from character string.
        // use ISO 8601: https://en.wikipedia.org/wiki/ISO_8601
        ISO_DATE_TIME: "yyyy-MM-ddTHH:mm:ss",
        VALID_FULL_TIMEDISPLAY_FORMAT_MAP: { "12": "12ampm", "24": "24" },
    };
}
