import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { ProfileApiI } from "../../pojo/ProfileI";
import { S25ItemI } from "../../pojo/S25ItemI";
import { TimeModelI } from "../../pojo/TimeModelI";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Bind } from "../../decorators/bind.decorator";
import { Event } from "../../pojo/Event";
import { S25OccurrenceDatepickerComponent } from "../s25-datepicker/s25.occurrence.datepicker.component";
import Occurrence = Event.Occurrence;

@TypeManagerDecorator("s25-ng-date-pattern-picker")
@Component({
    selector: "s25-ng-date-pattern-picker",
    template: `
        <div *ngIf="this.init">
            <div *ngIf="changeType">
                <label class="c-margin-bottom--half">
                    <span class="chooseText">Choose how you would like this to repeat:</span>
                    <select
                        class="cn-form__control c-margin-top--quarter c-margin-left--half"
                        [(ngModel)]="this.repeatType"
                        (change)="this.onPatternTypeChange($event)"
                    >
                        <option *ngFor="let type of repeatTypes" [ngValue]="type">{{ type.itemName }}</option>
                    </select>
                </label>
            </div>
            <div [ngSwitch]="this.repeatType?.itemTypeId">
                <!-- daily -->
                <s25-ng-date-pattern
                    [type]="'daily'"
                    *ngSwitchCase="1"
                    [(apiBean)]="this.dailyApiBean"
                    [timeModel]="this.timeModel"
                    [profileCode]="profileCode"
                    (apiBeanChange)="this.onApiChange($event); refreshCalendar()"
                ></s25-ng-date-pattern>

                <!-- weekly -->
                <s25-ng-date-pattern
                    [type]="'weekly'"
                    *ngSwitchCase="2"
                    [(apiBean)]="this.weeklyApiBean"
                    [timeModel]="this.timeModel"
                    [profileCode]="profileCode"
                    (apiBeanChange)="this.onApiChange($event); refreshCalendar()"
                ></s25-ng-date-pattern>

                <!-- monthly -->
                <s25-ng-date-pattern
                    [type]="'monthly'"
                    *ngSwitchCase="3"
                    [(apiBean)]="this.monthlyApiBean"
                    [timeModel]="this.timeModel"
                    [profileCode]="profileCode"
                    (apiBeanChange)="this.onApiChange($event); refreshCalendar()"
                ></s25-ng-date-pattern>

                <s25-ng-occurrence-datepicker
                    *ngIf="this.repeatType?.itemTypeId !== -1 && editNonAdHoc"
                    [firstDate]="timeModel?.evStartDt"
                    [(occurrences)]="adHocOccs"
                    (occurrencesChange)="setPatternType('Ad hoc'); adHocOccsChange.emit($event)"
                    [(apiBean)]="adHocApiBean"
                    [includeFirstDate]="true"
                    [validateDate]="validateAdHocDate"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [timeModel]="timeModel"
                ></s25-ng-occurrence-datepicker>

                <!-- ad hoc -->
                <div *ngSwitchCase="-1">
                    <div *ngIf="editAdHoc" [class.s25-datepattern-picker--flex]="showAdHocList">
                        <s25-ng-occurrence-datepicker
                            [firstDate]="timeModel?.evStartDt"
                            [(occurrences)]="adHocOccs"
                            [(apiBean)]="adHocApiBean"
                            [includeFirstDate]="includeFirstDate"
                            (apiBeanChange)="onApiChange($event)"
                            [validateDate]="validateAdHocDate"
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            [timeModel]="timeModel"
                        ></s25-ng-occurrence-datepicker>
                        <div *ngIf="showAdHocList" class="c-floatRight c-margin-left--half c-margin-left--quarter ">
                            <label class="ngBold content-header">Ad Hoc Dates</label>
                            <div *ngFor="let occ of adHocOccs; index as i" class="s25-datepattern-picker--flex">
                                <s25-ng-editable-date [readOnly]="true" [val]="occ.evStartDt"></s25-ng-editable-date>
                                <span
                                    *ngIf="adHocOccs.length > 1 && i > 0"
                                    (click)="removeOcc(occ.evStartDt)"
                                    (keydown.enter)="removeOcc(occ.evStartDt)"
                                    tabindex="0"
                                    class="c-svgIcon ngCpointer"
                                    role="button"
                                >
                                    <svg class="c-svgIcon ngCpointer">
                                        <title>Remove {{ occ }}</title>
                                        <use
                                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                        ></use>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [".s25-datepattern-picker--flex{display: flex;}"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25DatePatternPickerComponent implements OnInit {
    @Input() timeModel: TimeModelI;
    @Input() profileCode: string;
    @Input() adHocOccs: Occurrence[] = []; //Only used for ad hoc "patterns"
    @Input() editAdHoc = true; //We may not want to actually edit adHoc in this view
    @Input() showAdHocList: boolean = true; // Whether the date list should be shown next to the calendar
    @Input() editNonAdHoc: boolean = false; // Whether to show the calendar for non-ad-hoc patterns
    @Input() changeType = true;
    @Input() apiBean: any = {}; //ProfileApiI;
    @Input() validatePattern: Function;
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() includeFirstDate: boolean = false;
    @Output() apiBeanChange = new EventEmitter<ProfileApiI>();
    @Output() adHocOccsChange = new EventEmitter<Occurrence[]>();

    @ViewChild(S25OccurrenceDatepickerComponent) multiDatePicker: S25OccurrenceDatepickerComponent;

    origTimeModel: TimeModelI;
    init = false;
    repeatType: S25ItemI & RepeatType = {} as any;
    repeatTypes: RepeatType[] = [
        { itemTypeId: -2, itemName: "Does Not Repeat" },
        { itemTypeId: -1, itemName: "Ad hoc" },
        { itemTypeId: 1, itemName: "Daily" },
        { itemTypeId: 2, itemName: "Weekly" },
        { itemTypeId: 3, itemName: "Monthly" },
    ];

    dailyApiBean: ProfileApiI = {};
    weeklyApiBean: ProfileApiI = {};
    monthlyApiBean: ProfileApiI = {};
    @Input() adHocApiBean: ProfileApiI = {};

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {}

    @Bind
    setPatternType(name: RepeatType["itemName"]) {
        this.repeatType = this.repeatTypes.find((type) => type.itemName === name);
        this.sortAdHocOccs();
        this.cd.detectChanges();
    }

    onPatternTypeChange(obj?: any) {
        this.adHocOccs = [{ evStartDt: this.timeModel.evStartDt, evEndDt: this.timeModel.evEndDt }];
        this.refreshCalendar();
        this.onApiChange();
    }

    onApiChange(obj?: any) {
        this.sortAdHocOccs();
        this.cd.detectChanges();
        this.apiBeanChange.emit(this.apiBean);
    }

    refreshCalendar() {
        const occs = this.getOccurrences();
        if (occs) this.adHocOccs = occs;
    }

    @Bind
    changeFirstDt(dt: Date) {
        if (this.profileCode === "adhoc") {
            // If ad-hoc, discard all dates before current date
            const occs = this.adHocOccs.filter((occ) => occ.evStartDt < dt);
            for (let occ of occs) this.removeOcc(occ.evStartDt);
        } else if (this.profileCode && this.profileCode !== "dnr") this.refreshCalendar();

        this.removeOcc(this.origTimeModel.evStartDt);
        this.multiDatePicker.addOccurrence(dt);
        this.origTimeModel = S25Util.deepCopy(this.timeModel);
    }

    removeOcc(dt: any) {
        this.multiDatePicker.removeOccurrence(dt);
    }

    validateAdHocDate = (dt: Date) => {
        return this.validatePattern ? this.validatePattern(dt) : true;
    };

    @Bind
    getProfileModel() {
        if (!this.repeatType || this.repeatType.itemTypeId === -2) {
            return { type: "dnr" };
        } else if (this.repeatType.itemTypeId === -1) {
            return this.adHocApiBean.getProfileModel();
        } else if (this.repeatType.itemTypeId === 1) {
            return this.dailyApiBean.getProfileModel();
        } else if (this.repeatType.itemTypeId === 2) {
            return this.weeklyApiBean.getProfileModel();
        } else if (this.repeatType.itemTypeId === 3) {
            return this.monthlyApiBean.getProfileModel();
        } else {
            return { type: "dnr" };
        }
    }

    @Bind
    getOccurrences() {
        if (this.repeatType.itemTypeId === -2) {
            return [this.timeModel as Event.Occurrence];
        } else if (this.repeatType.itemTypeId === -1) {
            return this.adHocApiBean.getOccurrences?.();
        } else if (this.repeatType.itemTypeId === 1) {
            return this.dailyApiBean.getOccurrences?.();
        } else if (this.repeatType.itemTypeId === 2) {
            return this.weeklyApiBean.getOccurrences?.();
        } else if (this.repeatType.itemTypeId === 3) {
            return this.monthlyApiBean.getOccurrences?.();
        }
    }

    @Bind
    getProfileCode() {
        if (this.repeatType.itemTypeId === -2) {
            return "dnr";
        } else if (this.repeatType.itemTypeId === -1) {
            return "adhoc";
        } else if (this.repeatType.itemTypeId === 1) {
            return this.dailyApiBean.getProfileCode();
        } else if (this.repeatType.itemTypeId === 2) {
            return this.weeklyApiBean.getProfileCode();
        } else if (this.repeatType.itemTypeId === 3) {
            return this.monthlyApiBean.getProfileCode();
        } else {
            return "dnr";
        }
    }

    sortAdHocOccs() {
        this.adHocOccs = this.adHocOccs.sort((a: any, b: any) => Date.parse(a.evStartDt) - Date.parse(b.evStartDt));
    }

    ngOnInit(): void {
        this.origTimeModel = S25Util.deepCopy(this.timeModel);
        this.sortAdHocOccs();

        if (!this.profileCode) {
            this.repeatType = this.repeatTypes[0];
        } else if (this.profileCode === "dnr") {
            this.repeatType = this.repeatTypes[0];
        } else if (this.profileCode === "adhoc") {
            this.repeatType = this.repeatTypes[1];
        } else if (this.profileCode.startsWith("D")) {
            this.repeatType = this.repeatTypes[2];
        } else if (this.profileCode.startsWith("W")) {
            this.repeatType = this.repeatTypes[3];
        } else if (this.profileCode.startsWith("MD") || this.profileCode.startsWith("MP")) {
            this.repeatType = this.repeatTypes[4];
        } else {
            this.repeatType = this.repeatTypes[0];
        }

        this.apiBean.getProfileCode = this.getProfileCode;
        this.apiBean.getProfileModel = this.getProfileModel;
        this.apiBean.getOccurrences = this.getOccurrences;

        this.init = true;
        this.cd.detectChanges();
    }
}

export type RepeatType =
    | { itemTypeId: -2; itemName: "Does Not Repeat" }
    | { itemTypeId: -1; itemName: "Ad hoc" }
    | { itemTypeId: 1; itemName: "Daily" }
    | { itemTypeId: 2; itemName: "Weekly" }
    | { itemTypeId: 3; itemName: "Monthly" };
