//@author: devin
import { Pipe, PipeTransform } from "@angular/core";
import { S25Datefilter } from "./s25.datefilter.service";

@Pipe({
    name: "datePipe",
})
export class S25DatePipe implements PipeTransform {
    transform(date: any, format: string): string {
        return S25Datefilter.transform(date, format);
    }
}
