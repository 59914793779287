import { formatDate } from "@angular/common";
import { S25DateformatConst } from "./s25.dateformat.const";
import { s25Dateparser } from "./s25.dateparser.service";
import { S25BasicUtil } from "../../util/s25-basic-util";

export class S25Datefilter {
    private static DATE_FORMATS_SPLIT: RegExp =
        /((?:[^yMNnFfdHhmsaZEw}']+)|(?:'(?:[^']|'')*')|(?:E+|y+|M+|N+|n+|F+|f+|d+|H+|h+|m+|s+|a|Z|w+))(.*)/;
    private static DATETIME_FORMATS: any = {
        SHORTMONTH: "Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec".split(","),
        SHORTDAY: "Sun,Mon,Tue,Wed,Thu,Fri,Sat".split(","),
    };

    private static DATE_FORMATS: any = {
        NNN: S25Datefilter.dateStrGetter("Month", true, "toUpperCase"),
        nnn: S25Datefilter.dateStrGetter("Month", true, "toLowerCase"),
        FFF: S25Datefilter.dateStrGetter("Day", true, "toUpperCase"),
        fff: S25Datefilter.dateStrGetter("Day", true, "toLowerCase"),
    };

    private static TIME_FORMATS: any = {
        a: function (value: string) {
            return value.replace(new RegExp("AM", "g"), "am").replace(new RegExp("PM", "g"), "pm");
        },
    };

    private static parseFormat(format: string) {
        // get format tokens out of given format
        let parts: Array<string> = [];

        while (format) {
            var match = this.DATE_FORMATS_SPLIT.exec(format);
            if (match) {
                parts = parts.concat(Array.prototype.slice.call(match, 1));
                format = parts.pop();
            } else {
                parts.push(format);
                format = null;
            }
        }

        return parts;
    }

    private static dateStrGetter(name: string, isShortForm: boolean, postFormat: string) {
        return function (date: any, formats: any) {
            var value = date["get" + name]();
            var get = (isShortForm ? "SHORT" + name : name).toUpperCase();
            var result = formats[get][value];

            if (postFormat) {
                result = result[postFormat]();
            }

            return result;
        };
    }

    public static transform(date: any, format: string) {
        if (!date) {
            return;
        }

        try {
            let isS25dateFormat: boolean = false;

            // determine if format is defined and starts with 's25|'
            if (format && format.indexOf(S25DateformatConst.S25_DATE_FORMAT_PREFIX) === 0) {
                isS25dateFormat = true;
                format = format.substring(S25DateformatConst.S25_DATE_FORMAT_PREFIX.length);
            }

            // make sure date is Date() object; DateFilter returns original String when passed String,
            //    but we must apply the filter format, so we need Date()
            // angular datepicker-popup has a bug which sets ngModel.viewValue as a String the first time popup is created
            // http://stackoverflow.com/questions/24198669/angular-bootsrap-datepicker-date-format-does-not-format-ng-model-value/26427583#26427583
            if (typeof date === "string") {
                date = s25Dateparser.parse(date);
            }

            // call parent, get formatted result
            var result = formatDate(date, format, "en-US");

            // if failed -nothing can be done, return
            if (!result) {
                return result;
            }

            // if format starts with 's25|' - replace result with extended format options
            if (isS25dateFormat) {
                let parts = S25Datefilter.parseFormat(format);

                // for each format token - if is it contained in Extra formats - replace result with extra formatted value
                S25BasicUtil.forEach(parts, function (key: string) {
                    var fn = S25Datefilter.DATE_FORMATS[key];
                    if (fn) {
                        result = result.replace(new RegExp(key, "g"), fn(date, S25Datefilter.DATETIME_FORMATS));
                    }
                    fn = S25Datefilter.TIME_FORMATS[key];
                    if (fn) {
                        result = fn(result);
                    }
                });
            }

            return result;
        } catch (ex) {
            return null;
        }
    }
}
