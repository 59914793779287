// @author: devin
// @migratedBy: travis

import { S25Util } from "../../util/s25-util";
import { DatepickerApi } from "../s25-datepicker/date.picker.api";
import { jSith } from "../../util/jquery-replacement";
import { EventFormOccurrenceUtil } from "../s25-event-creation-form/occurrences/event.form.occurrence.util";
import { DayOfWeekI, PositionI, ProfileI } from "../../pojo/ProfileI";
import { TimeModelI } from "../../pojo/TimeModelI";
import { Event } from "../../pojo/Event";

let globalCounter = 0;
export type PatternEndChoices = "date" | "iterations" | "none";
export class ProfileUtil {
    public static getNextCounter() {
        globalCounter++;
        return globalCounter;
    }

    public static getProfileCodeEndingType(profileCode: string = "") {
        let repeatEnd: ProfileI["repeatEnd"] = "none";
        const throughDt = ProfileUtil.getProfileCodeThroughDateStr(profileCode);
        const endsAfter = ProfileUtil.getProfileCodeEndsAfter(profileCode);
        // getProfileCodeThroughDateStr for monthly by day repeat patterns like 'MD2 1-' we get '1-' which is not a valid date string
        if (!!throughDt && throughDt.length > 3) repeatEnd = "date";
        if (!!endsAfter) repeatEnd = "iterations";
        return repeatEnd;
    }

    public static getProfileCodeEndsAfter(profileCode: string) {
        let match = profileCode.match(/#(\d+)/);
        return match && match.length > 1 && match[1];
    }

    public static getDefaultThroughDate(timeModel: TimeModelI) {
        return timeModel.evStartDt && S25Util.date.addDays(S25Util.date.getDate(timeModel.evStartDt), 0);
    }

    //extract the throughDate String from the profile code, if there is no time (T) grab just the date
    //Careful around repeating monthly patterns w/o an end pattern as used in searching
    public static getProfileCodeThroughDateStr(profileCode: string) {
        let match = profileCode.match(/\s+(\d+T\d+)/) || profileCode.match(/\s+(\d+)/);
        return match && match.length > 1 && match[1];
    }

    public static getProfileCodeThroughDate(profileCode: string) {
        let throughDate = ProfileUtil.getProfileCodeThroughDateStr(profileCode);
        return throughDate && S25Util.date.parse(throughDate);
    }

    public static setThroughDate(repeatsThroughBean: any, profileModel: any, $scope: any) {
        repeatsThroughBean.date = profileModel.throughDate;
        $scope && DatepickerApi.refresh($scope);
    }

    public static setThroughDateOnProfileCode(throughDate: Date, profileCode: string) {
        if (profileCode.match(/\s+(\d+T\d+)/)) {
            return profileCode.replace(/\s+\d+T\d+/, " " + S25Util.date.toS25ISODateTimeStrNoPunc(throughDate));
        } else if (profileCode.match(/\s+(\d+)/)) {
            return profileCode.replace(/\s+\d+/, " " + S25Util.date.toS25ISODateTimeStrNoPunc(throughDate));
        }
    }

    public static resetThroughDate(repeatsThroughBean: any, profileModel: any, $scope: any, timeModel: TimeModelI) {
        profileModel.throughDate = ProfileUtil.getDefaultThroughDate(timeModel);
        ProfileUtil.setThroughDate(repeatsThroughBean, profileModel, $scope);
    }

    public static hasOverlaps(timeModel: TimeModelI, profileModel: any, throughDate: any) {
        let occ = ProfileUtil.getOccurrences(timeModel, profileModel, throughDate);
        occ.sort(S25Util.shallowSortDates("evStartDt", "evEndDt"));
        for (let i = 0; i < occ.length - 1; i++) {
            if (EventFormOccurrenceUtil.getEndDt(occ[i]) > EventFormOccurrenceUtil.getStartDt(occ[i + 1])) {
                return true;
            }
        }
        return false;
    }

    public static pushOcc(occurrences: Event.Occurrence[], evStartDt: Date, evEndDt: Date, timeModel: TimeModelI) {
        occurrences.push({
            evStartDt: evStartDt,
            evEndDt: evEndDt,
            minutes: timeModel.minutes,
        });
    }

    public static canPushOcc(initialStartDt: Date, occStartDt: Date, repeatEnd: String, throughDate: Date) {
        return (
            occStartDt >= initialStartDt &&
            (repeatEnd === "iterations" || S25Util.date.getDate(occStartDt) <= S25Util.date.getDate(throughDate))
        );
    }

    public static canPushOccByModel(
        timeModel: TimeModelI,
        profileModel: any,
        occStartDt: Date,
        throughDateOverride: Date,
    ) {
        return ProfileUtil.canPushOcc(
            timeModel.evStartDt,
            occStartDt,
            profileModel.repeatEnd,
            throughDateOverride || profileModel.throughDate,
        );
    }

    /*
        returns profile model: daily, weekly, monthly
    */
    public static getProfileModel(
        type: ProfileI["type"],
        profileCode: string,
        defaultThroughDate: Date,
        initStartDate?: Date,
    ): ProfileI {
        initStartDate = initStartDate || new Date();
        let parts = (profileCode && ["dnr", "adhoc"].indexOf(profileCode) === -1 && profileCode.split(/\s+/)) || [];
        let repeatsEvery = { itemId: 1 };
        let repeatBy: ProfileI["repeatBy"] = "day";
        let allDaysOfWeek = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
        let allDaysOfWeekName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        let daysOfWeek: any[] = [];
        let repeatByDay: PositionI[] = [];
        let repeatByPosition = [];
        let repeatEnd: ProfileI["repeatEnd"] = "date";
        let throughDate = defaultThroughDate;
        let endsAfter = 1;

        if (parts.length > 0) {
            type =
                type ||
                (parts[0][0] === "D"
                    ? "daily"
                    : parts[0][0] === "W"
                      ? "weekly"
                      : parts[0][0] === "M"
                        ? "monthly"
                        : "daily");
            let repeatsEveryInt = parseInt(parts[0].replace(/\D/g, "")); // 👉️ '123'
            repeatsEvery = (repeatsEveryInt && { itemId: repeatsEveryInt }) || repeatsEvery;
            repeatBy = parts[0].startsWith("MD") ? "day" : parts[0].startsWith("MP") ? "position" : "day"; //only used for monthly
            let i = 1;

            if (type === "weekly") {
                for (i = 1; i < parts.length; i++) {
                    let dow = parts[i];
                    if (allDaysOfWeek.indexOf(dow) > -1) {
                        daysOfWeek.push(dow);
                    } else {
                        break;
                    }
                }
                daysOfWeek = S25Util.array.unique(daysOfWeek);
                daysOfWeek = allDaysOfWeek.map(function (dow) {
                    return {
                        itemId: allDaysOfWeek.indexOf(dow),
                        chosen: daysOfWeek.indexOf(dow) > -1,
                        abbr: dow,
                        itemName: allDaysOfWeekName[allDaysOfWeek.indexOf(dow)],
                    };
                });
            } else if (type === "monthly") {
                for (i = 1; i < parts.length; i++) {
                    let part = parts[i] + "";
                    if (part.endsWith("+") || part.endsWith("-")) {
                        if (repeatBy === "day") {
                            //repeat by day, eg 1st day, 2nd day, 2nd-to-last-day, last-day...
                            repeatByDay.push({
                                itemId: ProfileUtil.getNextCounter(),
                                repeatStart: {
                                    //day of month
                                    itemId: parseInt(part.replace("+", "").replace("-", "")),
                                },
                                repeatFrom: {
                                    //direction (from start of month OR end of month)
                                    itemId: part.endsWith("+") ? "start" : "end",
                                },
                            });
                        } else {
                            //repeat by position, eg 1st monday, 2nd tuesday, last monday (of month)...
                            let position = {
                                itemId: ProfileUtil.getNextCounter(),
                                repeatStart: {
                                    //nth day of week occurrence, eg 1, 2, 3...
                                    itemId:
                                        parseInt(part.replace("+", "").replace("-", "")) *
                                        (part.endsWith("-") ? -1 : 1),
                                },
                                repeatFrom: {
                                    //day of week, eg MO, TU...
                                    itemId: 0, //default to SU to start
                                    abbr: allDaysOfWeek[0],
                                },
                            };

                            //next part has actual day of week, so fetch it
                            if (i + 1 < parts.length && allDaysOfWeek.indexOf(parts[i + 1]) > -1) {
                                position.repeatFrom.itemId = allDaysOfWeek.indexOf(parts[i + 1]);
                                position.repeatFrom.abbr = allDaysOfWeek[position.repeatFrom.itemId];
                                i++; //increment i so we skip over the next part since we just covered it here
                            }
                            repeatByPosition.push(position);
                        }
                    } else {
                        break;
                    }
                }
            }

            let idx = profileCode.indexOf("#");
            if (idx > -1) {
                repeatEnd = "iterations";
                endsAfter = parseInt(profileCode.substring(idx + 1)) || endsAfter;
            } else {
                repeatEnd = "date";
                throughDate = (parts.length > i && S25Util.date.parse(parts[i])) || throughDate;
            }
        }

        return {
            type: type,
            repeatBy: repeatBy,
            repeatsEvery: repeatsEvery,
            daysOfWeek:
                (daysOfWeek.length &&
                    daysOfWeek.filter(function (dow) {
                        return dow.chosen;
                    }).length &&
                    daysOfWeek) ||
                allDaysOfWeek.map(function (dow, index): DayOfWeekI {
                    return {
                        itemId: allDaysOfWeek.indexOf(dow),
                        chosen: index === initStartDate.getDay(),
                        abbr: dow,
                        itemName: allDaysOfWeekName[allDaysOfWeek.indexOf(dow)],
                    };
                }),
            repeatByDay: repeatByDay,
            repeatByPosition: repeatByPosition,
            repeatEnd: repeatEnd,
            throughDate: throughDate,
            endsAfter: endsAfter,
        };
    }

    public static getProfileCodeByModel(profileModel: any) {
        return ProfileUtil.getProfileCode(
            profileModel.type,
            profileModel.repeatsEvery,
            profileModel.repeatEnd,
            profileModel.throughDate,
            profileModel.endsAfter,
            profileModel.daysOfWeek,
            profileModel.repeatBy,
            profileModel.repeatByDay,
            profileModel.repeatByPosition,
        );
    }

    public static getProfileCode(
        type?: ProfileI["type"],
        repeatsEvery?: ProfileI["repeatsEvery"],
        repeatEnd?: ProfileI["repeatEnd"],
        throughDate?: Date,
        endsAfter?: string,
        daysOfWeek?: any[],
        repeatBy?: string,
        repeatByDay?: any[],
        repeatByPosition?: any[],
    ) {
        return (
            (type === "daily" ? "D" : type === "weekly" ? "W" : "M" + (repeatBy === "day" ? "D" : "P")) +
            repeatsEvery.itemId +
            " " +
            (type === "weekly"
                ? daysOfWeek
                      .filter(function (dow) {
                          return dow.chosen;
                      })
                      .map(function (dow) {
                          return dow.abbr;
                      })
                      .join(" ") + " "
                : "") +
            (type === "monthly"
                ? (repeatBy === "day"
                      ? repeatByDay
                            .map(function (r) {
                                //day of month, from start or end of month
                                return r.repeatStart.itemId + (r.repeatFrom.itemId === "start" ? "+" : "-");
                            })
                            .join(" ")
                      : repeatByPosition
                            .map(function (r) {
                                //nth weekday of month, from start or end of month
                                return (
                                    Math.abs(r.repeatStart.itemId) +
                                    (r.repeatStart.itemId > 0 ? "+" : "-") +
                                    " " +
                                    r.repeatFrom.abbr
                                );
                            })
                            .join(" ")) + " "
                : "") +
            profileCodeEnd(repeatEnd, throughDate, endsAfter)
        );
    }

    /*
        timeModel: evStartDt (initial start date), evEndDt (initial end date), minutes (setupMinutes, preMinutes, postMinutes, takedownMinutes)
    */
    public static getOccurrences(
        timeModel: TimeModelI,
        profileModel: ProfileI,
        throughDateOverride?: Date,
    ): Event.Occurrence[] {
        const occ: any[] = [];

        //push initial date as first occurrence
        ProfileUtil.pushOcc(
            occ,
            S25Util.date.clone(timeModel.evStartDt),
            S25Util.date.clone(timeModel.evEndDt),
            timeModel,
        );

        //calculate iterations
        var iterations = 1;
        if (profileModel.repeatEnd === "iterations") {
            iterations = profileModel.endsAfter;
        } else {
            if (profileModel.type === "monthly") {
                //calc iterations from through date
                iterations =
                    Math.max(
                        0,
                        S25Util.date.diffMonths(timeModel.evStartDt, throughDateOverride || profileModel.throughDate),
                    ) + 1;
            } else {
                //calc iterations from through date
                if (profileModel.type === "daily") {
                    iterations =
                        S25Util.date.diffDays(timeModel.evStartDt, throughDateOverride || profileModel.throughDate) + 1;
                } else {
                    //weekly
                    iterations = S25Util.date.diffWeeks(
                        timeModel.evStartDt,
                        throughDateOverride || profileModel.throughDate,
                    );
                }
            }
        }

        iterations = Math.max(iterations, 1); //min iterations should be 1, so take MAX of iterations and 1
        var durationMin = S25Util.date.diffMinutes(timeModel.evStartDt, timeModel.evEndDt); //duration for easy end dt computation
        for (var i = 0; i < iterations; i++) {
            var startDt = null,
                endDt = null;

            if (profileModel.type === "daily") {
                startDt = S25Util.date.addDays(timeModel.evStartDt, i * profileModel.repeatsEvery.itemId);
                endDt = S25Util.date.addDays(timeModel.evEndDt, i * profileModel.repeatsEvery.itemId);
                if (ProfileUtil.canPushOccByModel(timeModel, profileModel, startDt, throughDateOverride)) {
                    ProfileUtil.pushOcc(occ, startDt, endDt, timeModel);
                }
            } else if (profileModel.type === "weekly") {
                //add weeks and adjust to start of week (0-based Sunday week)
                var startOfWeek = S25Util.date.firstDayOfWeek(
                    S25Util.date.addWeeks(timeModel.evStartDt, i * profileModel.repeatsEvery.itemId),
                    0,
                );

                //for each day of week...
                jSith.forEach(profileModel.daysOfWeek, function (key, dow) {
                    startDt = S25Util.date.addDays(startOfWeek, dow.itemId); //get start date based on start of week and day of week in play
                    endDt = S25Util.date.addMinutes(startDt, durationMin);
                    //if day of week chosen (initial date already added above), and this date is >= the initial date (don't want to include days in the past due to
                    //start of week adjustment)
                    if (
                        dow.chosen &&
                        ProfileUtil.canPushOccByModel(timeModel, profileModel, startDt, throughDateOverride)
                    ) {
                        ProfileUtil.pushOcc(occ, startDt, endDt, timeModel);
                    }
                });
            } else if (profileModel.type === "monthly") {
                var startOfMonth = S25Util.date.syncDateToTime(
                    S25Util.date.firstDayOfMonth(
                        S25Util.date.addMonths(timeModel.evStartDt, i * profileModel.repeatsEvery.itemId),
                    ),
                    timeModel.evStartDt,
                );
                var endOfMonth = S25Util.date.syncDateToTime(
                    S25Util.date.lastDayOfMonth(startOfMonth),
                    timeModel.evStartDt,
                );
                if (profileModel.repeatBy === "day") {
                    jSith.forEach(profileModel.repeatByDay, function (key, item) {
                        var startDt, endDt;

                        //subtract 1 from itemId bc it is 1 - 31 (for display and easy profile code building convenience) and should be 0 - 30 here since it is "days from start of month"
                        if (item.repeatFrom.itemId === "start") {
                            startDt = S25Util.date.addDays(startOfMonth, item.repeatStart.itemId - 1);
                        } else {
                            startDt = S25Util.date.addDays(endOfMonth, -1 * (item.repeatStart.itemId - 1));
                        }

                        if (S25Util.date.equalMonth(startOfMonth, startDt)) {
                            endDt = S25Util.date.addMinutes(startDt, durationMin);
                            if (ProfileUtil.canPushOccByModel(timeModel, profileModel, startDt, throughDateOverride)) {
                                ProfileUtil.pushOcc(occ, startDt, endDt, timeModel);
                            }
                        }
                    });
                } else {
                    jSith.forEach(profileModel.repeatByPosition, function (key, item) {
                        var startDt, endDt, ithOccCounter, ithDt, i, equalMonth;
                        ithOccCounter = 0;
                        for (i = 0; i < 50; i++) {
                            var monthBookEnd = item.repeatStart.itemId > 0 ? startOfMonth : endOfMonth;
                            var multiplier = item.repeatStart.itemId > 0 ? 1 : -1;
                            ithDt = S25Util.date.addDays(monthBookEnd, i * multiplier);
                            if (ithDt.getDay() === item.repeatFrom.itemId) {
                                ithOccCounter = ithOccCounter + multiplier;
                                equalMonth = S25Util.date.equalMonth(startOfMonth, ithDt);
                                if (ithOccCounter === item.repeatStart.itemId && equalMonth) {
                                    startDt = ithDt;
                                    break;
                                } else if (!equalMonth) {
                                    break;
                                }
                            }
                        }
                        if (startDt) {
                            endDt = S25Util.date.addMinutes(startDt, durationMin);
                            if (ProfileUtil.canPushOccByModel(timeModel, profileModel, startDt, throughDateOverride)) {
                                ProfileUtil.pushOcc(occ, startDt, endDt, timeModel);
                            }
                        }
                    });
                }
            }
        }

        return S25Util.array.uniqueByProp(occ, "evStartDt");
    }
}

function profileCodeEnd(repeatEnd: ProfileI["repeatEnd"], throughDate: Date, endsAfter: string) {
    switch (repeatEnd) {
        case "date":
            return S25Util.date.toS25ISODateTimeStrNoPuncEndOfDay(throughDate);
        case "none":
            return "";
        case "iterations":
        default:
            return "#" + endsAfter;
    }
}
