/**
 * Converts s25 user datetimepref object into Angular's dateFilter string.
 * Based on SQL dbo.f_format_date() except it returns Angular's dateFilter instead of formatted date.
 *
 * Test:
 * ```
 * var datetimeprefs = {weekstart: 7, timedisplay: 12, datesep: 'S', month: 'I', day: 'I', dateorder: 'MDY'};
 * console.log(S25Dateformat.getDateTimeFormat(datetimeprefs));
 * console.log(datetimeprefs);
 *```
 *
 * @author: mikhail, devin
 */

import { S25DateformatConst } from "./s25.dateformat.const";
import { S25BasicUtil } from "../../util/s25-basic-util";
import { UserPrefsI } from "../../pojo/UserPrefsI";

export class S25Dateformat {
    public static getDateformat(userprefs: UserPrefsI): string {
        return S25Dateformat._convert(userprefs, S25DateformatConst.S25_DATE_FORMAT_CONST.dateprefs);
    }

    public static getTimeformat(userprefs: UserPrefsI): string {
        // sanitize - set any value other than (12,24) as prefs=undefined and default will be used, i.e. '12ampm'
        // note: cannot have prefs as {timedisplay: undefined} because it'll override default with undefined
        // add 'ampm' if timedisplay='12' - i.e. 'ampm' only applies to 12-hour based system
        // will be undefined if userprefs.timedisplay not in VALID_FORMAT_MAP
        let validFullTimedisplay: string =
            S25DateformatConst.S25_DATE_FORMAT_CONST.VALID_FULL_TIMEDISPLAY_FORMAT_MAP[userprefs.timedisplay];
        let prefs: UserPrefsI = validFullTimedisplay && { timedisplay: validFullTimedisplay };
        return S25Dateformat._convert(prefs, S25DateformatConst.S25_DATE_FORMAT_CONST.timeprefs);
    }

    public static getTimeformatShort(userprefs: UserPrefsI): string {
        // make sure no ampm used from prefsDefault
        let prefs: UserPrefsI = { timedisplay: userprefs.timedisplay || "12" };
        return S25Dateformat._convert(prefs, S25DateformatConst.S25_DATE_FORMAT_CONST.timeprefs);
    }

    public static getTimeformatAMPM(): string {
        let prefs: UserPrefsI = { timedisplay: "ampm" };
        return S25Dateformat._convert(prefs, S25DateformatConst.S25_DATE_FORMAT_CONST.timeprefs);
    }

    public static getISODateTimeFormat(): string {
        return S25DateformatConst.S25_DATE_FORMAT_CONST.ISO_DATE_TIME;
    }

    public static getDateTimeFormat(userprefs: UserPrefsI): string {
        return (
            S25Dateformat.getDateformat(userprefs) +
            " " +
            S25Dateformat.getTimeformat(userprefs).replace(S25DateformatConst.S25_DATE_FORMAT_PREFIX, "")
        );
    }

    public static _convert(userprefs: UserPrefsI, formatPrefs: any): string {
        // copy, so we don't modify original userprefs
        let prefs: UserPrefsI = { ...formatPrefs.prefsDefault };

        // override any specified fields, and add any non-specified fields
        Object.assign(prefs, userprefs);

        // map values to Angular's dateFilter
        S25BasicUtil.forEach(formatPrefs.prefsMapping, function (obj: any, key: string) {
            let value = prefs[key];
            // non-matched fields - leave as is
            if (value) {
                prefs[key] = obj[value] || value;
            }
        });

        let result = "";

        // dateformat
        if (formatPrefs.prefsDateOrderMapping) {
            // map dateFilter values into the order array
            let arr: Array<string | number> = prefs.dateorder.split("");
            S25BasicUtil.forEach(arr, function (value: string, i: number) {
                arr[i] = prefs[formatPrefs.prefsDateOrderMapping[value]];
            });
            // flatten with datesep
            result = arr.join(prefs.datesep);
            // always prepend with weekday separated by space (as Backbase does)
            result = prefs.day + " " + result;
        } else {
            result = prefs.timedisplay;
        }

        // compose result string based on prefDateOrderMapping
        return S25DateformatConst.S25_DATE_FORMAT_PREFIX + result;
    }
}
