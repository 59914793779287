//@author: devin
import { S25DatePipe } from "./s25.date.pipe";
import { NgModule } from "@angular/core";

@NgModule({
    declarations: [S25DatePipe],
    exports: [S25DatePipe],
    providers: [S25DatePipe],
})
export class S25DateModule {}
